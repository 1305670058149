const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "站点名称",
                prop: "STATIONNAME"
            }, {
                label: "发布时间",
                prop: "TIME"
            }, {
                label: "当前气温(℃)",
                prop: "TEMP"
            }, {
                label: "一小时内的降雨量(mm)",
                prop: "RAIN"
            }, {
                label: "平均风速(m/s)",
                prop: "WIND_SPEED"
            }, {
                label: "气压(hPa)",
                prop: "PRESSURE"
            }, {
                label: "能见度(m)",
                prop: "VISIBILITY"
            }],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        // selectData: [{
        //     label: '部门',
        //     clearable: true,
        //     placeholder: "请选择部门",
        //     list: [],
        //     optionLabel: 'NAME',
        //     optionValue: 'ID',
        //     value: 'departmentValue',
        //     operateType: 'search',
        //     isShow: true
        // }, {
        //     label: '角色',
        //     clearable: true,
        //     placeholder: "请选择角色",
        //     list: [],
        //     optionLabel: 'NAME',
        //     optionValue: 'ID',
        //     value: 'roleValue',
        //     operateType: 'search',
        //     isShow: true
        // }],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };